import React, { Component, createContext } from "react";
import { auth, generateUserDocument } from "../firebase";


export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: null
  };

  componentDidMount = async () => {
    auth.onAuthStateChanged(async userAuth => {
      (generateUserDocument);
      let user = userAuth;
      //const user = userAuth;
      if(user) {
        if(user.emailVerified) {
          /*
          try {
            user = await generateUserDocument(userAuth);
          } catch(err) {
            console.log("Failed to create user with additional info");
          }
          */
          this.setState({ user });
        }
      }
    });
  };


  render() {
    const { user } = this.state;

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
