import React, { Component } from 'react';
import { auth } from "../firebase";

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChange extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    auth.currentUser.updatePassword(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
        <div className="mt-8">
            <h1 className="text-3xl mb-2 text-center font-bold">Change Password</h1>
            <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
            <form onSubmit={this.onSubmit}>
                <label htmlFor="passwordOne" className="block">
                    New Password:
                </label>
                    
                <input
                    name="passwordOne"
                    className="mt-1 mb-3 p-1 w-full"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder="New Password"
                />

                <label htmlFor="passwordTwo" className="block">
                    Confirmed New Password:
                </label>
        
                <input
                    name="passwordTwo"
                    className="mt-1 mb-3 p-1 w-full"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm New Password"
                />

                <button disabled={isInvalid} className="bg-green-400 hover:bg-green-500 w-full py-2 text-white" type="submit">
                    Reset My Password
                </button>

                {error && <p>{error.message}</p>}
            </form>
        </div>
    </div>
    );
  }
}

export default PasswordChange;