import React, { useContext } from "react";
import { UserContext } from "./UserProvider";
import {auth} from "../firebase";
import { Link } from "@reach/router";
import { navigate } from "@reach/router"

const ProfilePage = () => {
  const user = useContext(UserContext);
  const {photoURL, displayName, email} = user;
  console.log(user);
  

  return (
    <div className = "mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
      <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
        <div
          style={{
            background: `url(${photoURL || 'img/avatar.jpg'})  no-repeat center center`,
            backgroundSize: "cover",
            height: "200px",
            width: "200px"
          }}
          className="border border-blue-300"
        ></div>
        <div className = "md:pl-4">
        <h2 className = "text-2xl font-semibold">{displayName}</h2>
        <h3 className = "italic_">{email}</h3>
        </div>
      </div>
      <button className = "w-full py-3 bg-red-600 mt-4 text-white" onClick = {() => {
          auth.signOut(); 
          navigate('/');
      }}>Sign out</button>
      
      <p className="text-center my-3">
          Need change password?{" "}
          <Link to="/authentication/passwordChange" className="text-blue-500 hover:text-blue-600">
            Change Password
          </Link>
          <br />{" "}

          Want to change profile?{" "}
          <Link to="/authentication/profileChange" className="text-blue-500 hover:text-blue-600">
            Change Profile
          </Link>
          <br />{" "}

          Need change EMail?{" "}
          <Link to="/authentication/emailChange" className="text-blue-500 hover:text-blue-600">
            Change EMail
          </Link>
      </p>
    </div>
  ) 
};

export default ProfilePage;

