import React, { Component } from "react";

export class Copyright extends Component {
  render() {
    return (
        <div id="footer">
          <div className="container text-center">
            <div style={{fontWeight: "bold"}}>
              &copy; 2021. Ultimate Software Ltd.  {  }
              <a href="https://ultimate.codes" rel="nofollow">
                Ultimate Software Co.
              </a>
              All Rights Reserved.
            </div>
          </div>
        </div>
    );
  }
}

export default Copyright;
