import React, { Component } from 'react';
import { auth } from "../firebase";

const INITIAL_STATE = {
  emailOne: '',
  emailTwo: '',
  error: null,
};

class EMailChange extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { emailOne } = this.state;

    auth.currentUser.updateEmail(emailOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { emailOne, emailTwo, error } = this.state;

    const isInvalid =
      emailOne !== emailTwo || emailOne === '';

    return (
        <div className="mt-8">
            <h1 className="text-3xl mb-2 text-center font-bold">Change EMail</h1>
            <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
      
            <form onSubmit={this.onSubmit}>
                <label htmlFor="emailOne" className="block">
                    New EMail:
                </label>
        
                <input
                    name="emailOne"
                    className="mt-1 mb-3 p-1 w-full"
                    value={emailOne}
                    onChange={this.onChange}
                    type="email"
                    placeholder="New EMail"
                />

                <label htmlFor="emailTwo" className="block">
                    Confirmed New EMail:
                </label>
                <input
                    name="emailTwo"
                    className="mt-1 mb-3 p-1 w-full"
                    value={emailTwo}
                    onChange={this.onChange}
                    type="email"
                    placeholder="Confirm New EMail"
                />

                <button disabled={isInvalid} className="bg-green-400 hover:bg-green-500 w-full py-2 text-white" type="submit">
                    Reset My EMail
                </button>

                {error && <p>{error.message}</p>}
            </form>
        </div>
    </div>
    );
  }
}

export default EMailChange;