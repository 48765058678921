
import React, { Component } from 'react'
import Navigation from './components/navigation';
import Authentication from './components/Authentication';
import { Router } from "@reach/router";
import Main from './Main';
import Copyright from './components/Copyright';

import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import PasswordReset from "./components/PasswordReset";
import PasswordChange from "./components/PasswordChange";
import UserProvider from "./components/UserProvider";
import ProfileChange from "./components/ProfileChange";
import EMailChange from "./components/EMailChange";

export class App extends Component {

  render() {
    return ( 
      <UserProvider>
        <div> 
          <Navigation />
          <p className = "height80px"></p>
          <Router>
              <Authentication path = "authentication" />
              <Main path = "/" />
              <SignIn path = "authentication/signIn" />
              <SignUp path="authentication/signUp" />
              <PasswordReset path="authentication/passwordReset" />
              <PasswordChange path="authentication/passwordChange" />
              <ProfileChange path="authentication/profileChange" />
              <EMailChange path="authentication/emailChange" />
         </Router>
          <p className = "height80px"></p>
          <Copyright  />
        </div>
      </UserProvider>

    )
  }
}

export default App;
