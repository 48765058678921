import React, { Component } from 'react'
import Header from './components/header';
import Features from './components/features';
//import About from './components/about';
import Screenshots from './components/screenshot';
import Contact from './components/contact';
import Extensions from './components/Extension';

export class Main extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    let JsonData = {
      "Header": {
          "title": "Ultimate Copy",
          "paragraph": "We are providing a brand new way to copy something that will simplify your copy action and improve your productive significantly"
  
      },
      "Contact": {
          "phone": "+1 12345678",
          "email": "contact@ultimate.codes",
          "facebook": "https://www.facebook.com/Ultimate-Software-103293851813456",
          "twitter": "https://mobile.twitter.com/ultimatesoftwa4",
          "youtube": "https://www.youtube.com/channel/UCoDFvjWQVp24HhLL5XwGLMw"
      },
      "Extensions": [{
        "img": "img/extension/01.jpg",
        "url": "https://chrome.google.com/webstore/detail/paaste-highlighter/hloljadkghhkpiheglldgomhgcafdpip",
        "browser": "Chrome Extension"
      },
      {
        "img": "img/extension/02.jpg",
        "url": "Mike Doe",
        "browser": "Firefox Extension"
      }, {
        "img": "img/extension/03.png",
        "url": "Jane Doe",
        "browser": "Edge Add-on"
      },
      {
        "img": "img/extension/04.png",
        "url": "Karen Doe",
        "browser": "Opera Extension"
      }
      ],
      "Features": [{
              "icon": "fa fa-comments-o",
              "title": "Copy in text",
              "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
          },
          {
              "icon": "fa fa-bullhorn",
              "title": "Copy in screenshot",
              "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
          },
          {
              "icon": "fa fa-group",
              "title": "Choose element easily",
              "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
          },
          {
              "icon": "fa fa-magic",
              "title": "Lorem ipsum",
              "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
          }
      ]
    };
  
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return ( 
      <div> 
        <Header data={this.state.landingPageData.Header} />
        <Features data={this.state.landingPageData.Features} />
        <Screenshots />
        <Extensions data={this.state.landingPageData.Extensions} />
        <Contact data={this.state.landingPageData.Contact} />
      </div>

    )
  }
}

export default Main;
