import React, { } from "react";
import Application from "./Application";
//import UserProvider from "./UserProvider";

function Authentication() {
  
  return (
    <Application />
  );
}

export default Authentication;
