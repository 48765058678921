import React, { Component } from "react";

export class Extension extends Component {
  render() {
    return (
      <div id="extension" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Browser Extensions</h2>
            <p>
              You can install the extension in Chrome, Firefox, Microsoft Edge, Opera.
            </p>
          </div>
          <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                    <div className="thumbnail">
                      {" "}
                      <a href={d.url}  target="_blank">
                          <img src={d.img} alt="..." className="team-img" />
                          <div className="caption">
                            <p>{d.browser}</p>
                          </div>
                      </a>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Extension;