/*global chrome*/

import React, {useState} from "react";
import { Link } from "@reach/router";
import { signInWithGoogle } from "../firebase";
import { auth } from "../firebase";
import { functions } from "firebase";
import { navigate } from "@reach/router"


const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event,email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password)
        .then(function(firebaseUser) {
          if(!firebaseUser.user.emailVerified) {
            auth.signOut()
                .then(function () {
                  console.log("Logged out because email not verified");
                }).catch(function (error) {
                    // An error happened.
                    console.log("Failed to log out: ", error);
                });

            firebaseUser.user.sendEmailVerification().then(function() {
                alert("Verification email sent to your mailbox, please check your email");
                navigate('/');
            }).catch(function(error) {
                setError({info: 'Failed to send verification email: ' + error, class: "py-4 bg-red-600 w-full text-white text-center mb-3"});
            });
          
            alert("Please verify your email first and then sign in again");
            setError({info: "EMail not verified yet!", class: "py-4 bg-red-600 w-full text-white text-center mb-3"});
            navigate('/');
            return;
          }
          // Success 
          setError({info: "Signed in with password and email successfully!", class: "py-4 bg-green-600 w-full text-white text-center mb-3"});
          console.log("Signed in with password and email as user: ", firebaseUser.user.uid);


          firebaseUser.user.getIdToken()
          .then(idToken => {
              var ucExtensionId = "lfpcjmmhgbdggmpbmkpmkfpcpnjacjbf";

              chrome.runtime.sendMessage(ucExtensionId, {
                  message: "currentUserWithIdToken",
                  idToken: idToken
              });
              

              functions().httpsCallable("getCustomToken")({
                  idToken: idToken
              })
              .then((function(customTokenInfo) {
                  console.log(customTokenInfo.data);
                  
                  
                  chrome.runtime.sendMessage(ucExtensionId, {
                      message: "currentUserWithCustomToken",
                      customToken: customTokenInfo.data
                  });
                  
                  
                  alert("Custom Token sent to the extension");
              }))
              .catch(err => {
                  console.log(err);
                  alert(err);
              });
          });

        })
        .catch(error => {
          setError({info: "Failed to signin: " + error, class: "py-4 bg-red-600 w-full text-white text-center mb-3"});
          console.error("Error signing in with password and email", error);
        });
      };
      
      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;
        
          if(name === 'userEmail') {
              setEmail(value);
          }
          else if(name === 'userPassword'){
            setPassword(value);
          }
      };
   

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && 
          <div className = {error.class}>
            {error.info}
          </div>}
        <form className="">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full"
            name="userEmail"
            value = {email}
            placeholder="Your Email"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            className="mt-1 mb-3 p-1 w-full"
            name="userPassword"
            value = {password}
            placeholder="Your Password"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
          <button className="bg-green-400 hover:bg-green-500 w-full py-2 text-white" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
            Sign in
          </button>
        </form>
        <p className="text-center my-3">or</p>
        <button
          className="bg-green-500 hover:bg-blue-600 w-full py-2 text-white"
          onClick={() => {
            signInWithGoogle();
          }}
        >
          Sign in with Google
        </button>
        <p className="text-center my-3">
          Don't have an account?{" "}
          <Link to="/authentication/signUp" className="text-blue-500 hover:text-blue-600">
            Sign up here
          </Link>{" "}
          <br />{" "}
          <Link to="/authentication/passwordReset" className="text-blue-500 hover:text-blue-600">
            Forgot Password?
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
