import React, { useContext } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ProfilePage from "./ProfilePage";
import { UserContext } from "./UserProvider";
import PasswordReset from "./PasswordReset";
import PasswordChange from "./PasswordChange";
import ProfileChange from "./ProfileChange";
import EMailChange from "./EMailChange";

function Application() {
  const user = useContext(UserContext);
  return (
        user ? 
        <div>
          <ProfilePage />
          <Router> 
            <PasswordChange path = "passwordChange" />
            <EMailChange path = "emailChange" />
            <ProfileChange path = "profileChange" />
          </Router>
        </div>
      :
        <Router> 
          <SignUp path="singUp" />
          <SignIn path="/" />
          <PasswordReset path = "passwordReset" />
          <PasswordChange path = "passwordChange" />
        </Router>
  );
}

export default Application;
