import React, { Component } from 'react';
import { auth } from "../firebase";

const INITIAL_STATE = {
  displayName: '',
  photoURL: '',
  error: null,
};

class ProfileChange extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { displayName, photoURL } = this.state;

    auth.currentUser.updateProfile({displayName: displayName, photoURL: photoURL})
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { displayName, photoURL, error } = this.state;

    const isInvalid =
    displayName !== "" || photoURL !== '';

    return (
        <div className="mt-8">
            <h1 className="text-3xl mb-2 text-center font-bold">Change Profile</h1>
            <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
            <form onSubmit={this.onSubmit}>
                <label htmlFor="displayName" className="block">
                    New Display Name:
                </label>

                <input
                    name="displayName"
                    className="mt-1 mb-3 p-1 w-full"
                    value={displayName}
                    onChange={this.onChange}
                    type="text"
                    placeholder="New Display Name"
                />

                <label htmlFor="photoURL" className="block">
                    New Photo URL:
                </label>

                <input
                    name="photoURL"
                    className="mt-1 mb-3 p-1 w-full"
                    value={photoURL}
                    onChange={this.onChange}
                    type="text"
                    placeholder="New Photo URL"
                />
        
                <button disabled={isInvalid} className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"  type="submit">
                    Set New Profile
                </button>

                {error && <p>{error.message}</p>}
            </form>
        </div>
    </div>
    );
  }
}

export default ProfileChange;